import React, { Component } from "react"
import { Link } from "gatsby"
import wechatCode from "../images/wechat-code.png"

class Footer extends Component {
  constructor(props) {
    super(props)

    console.log(props.promosol)

    this.state = {
      showWechatCode: false,
    }
    this.showWechatCode = this.showWechatCode.bind(this)
    this.hideWechatCode = this.hideWechatCode.bind(this)
  }

  showWechatCode() {
    this.setState({ showWechatCode: true })
  }

  hideWechatCode() {
    this.setState({ showWechatCode: false })
  }

  render() {
    return (
      <footer>
        <div className="footer-top">
          <div className="content-wrap">
            <h2>联系我们</h2>
            <h4>留下您的联系方式，我们的客服将为您提供试用服务</h4>
            <div className="contect-form">
              <form action="submit">
                <div className="input-text input-text-m">
                  <input type="text" placeholder="如何称呼您" />
                </div>
                <div className="input-text input-text-l">
                  <input type="text" placeholder="您的联系电话" />
                </div>
                <div className="input-text input-text-l">
                  <input type="text" placeholder="您的需求" />
                </div>
                <button className="btn-l btn-solid btn-blue">提交</button>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-bg"></div>
          <div className="footer-bottom-content">
            <div className="content-wrap">
              <div className="footer-links-group">
                <div className="footer-links">
                  <p>产品介绍</p>
                  <Link to="/products/product-luma">Luma虚拟助理</Link>
                  <Link to="/products/product-auto">Luma自动化</Link>
                  <Link to="/products/product-ism">Luma服务管理</Link>
                  <Link to="/products/product-AIOps">Luma智能运维</Link>
                </div>
                <div className="footer-links">
                  <p>解决方案</p>
                  {this.props.promosol.edges.map(({ node }) => (
                    <Link to={"/solutions#" + node.id} key={node.id}>
                      {node.frontmatter.subtitle}
                    </Link>
                  ))}
                </div>
                <div className="footer-links support">
                  <p>客户支持</p>
                  <Link to="/terms">合同条款</Link>
                  <Link to="/blog">最新消息</Link>
                  <a href="https://weibo.com/serviceai">关注微博</a>
                  <h5 className="wechat" onClick={this.showWechatCode}>
                    微信公号
                  </h5>
                  <div
                    className={
                      this.state.showWechatCode
                        ? "wechat-code show"
                        : "wechat-code"
                    }
                  >
                    <img src={wechatCode} alt="" />
                    <div className="hideWechat" onClick={this.hideWechatCode}>
                      关闭
                    </div>
                  </div>
                </div>
                <div className="footer-links">
                  <p>联系销售</p>
                  <h5>Alice Zhong （Sales）</h5>
                  <h5>邮箱及电话：</h5>
                  <a href="mailto:Alice.zhong@serviceai.cn">
                    Alice.zhong@serviceai.cn
                  </a>
                  <a href="callto:62138000">62138000</a>
                </div>
                <div className="footer-links">
                  <p>公司地址</p>
                  <h5>上海市徐汇区武康路</h5>
                  <h5>333号霞飞别墅5栋</h5>
                  <h5>电话：62138000</h5>
                  <h5>传真：62137000</h5>
                </div>
              </div>
              <p>© {new Date().getFullYear()} ServiceAI. All Rights Reserved</p>
              <p>沪ICP备20018180号</p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
